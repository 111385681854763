.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navigation Styles */

/* .navigation-wrapper {
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0;
} */

.home-title {
  width: 60%;
  margin: 0 auto;
  padding: 4%;
}
h1 {
  color: black;
  font-weight: bold;
  font-size: 1.4em;
}

h2 {
  color: black;
  font-size: 0.8em;
}

.anchor-tag {
  text-decoration: unset;
}

.home-styles {
  width: 70%;
  text-align: center;
}

.grid-work {
  margin: 2% 0 2% 0;
}

.separator {
  color: grey;
  display: flex;
  align-items: center;
  text-align: center;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px dashed grey;
}
.separator::before {
  margin-right: 0.25em;
}
.separator::after {
  margin-left: 0.25em;
}

.icon-grid {
  width: 6%;
  height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

/* Portfolio Styles */
.portfolio-wrapper {
  width: 70%;
  margin: 10% auto;
}

/* Animations */

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

/* Foster Together Page */

.foster-page {
  width: 100%;
  height: 100vh;
  background: white;
}

.foster-page .main-content {
  text-align: center;
  margin: 5% auto;
}

.about-me-img {
  width: 52%;
}
